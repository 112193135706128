<template>
<b-dropdown ref="team-select-dropdown" class="btn-select-dropdown" no-caret variant="link-dark" no-flip toggle-class="text-decoration-none">
      <template v-slot:button-content >
            <div v-if="selected" class="groups-team m-2 py-2 px-4">
                  <div>
                        <b-img :src="flag(selected)" />
                  </div>
                  <div>
                        {{selected.dispname}}
                  </div>
            </div>
            <div v-else class="groups-team m-2 py-2 px-4">
                  Välj lag
            </div>
      </template>
      <div v-if="!loading" class="btn-select">
            <b-row v-if="showclear && id" class="align-items-center btn-select-row" @click="SelectTeam(false)">
                  <b-col class="text-center p-3 bg-danger text-white">
                        Rensa val
                  </b-col>
            </b-row>
            <b-row @click="SelectTeam(team)" v-for="team in teams" :key="team.id" class="align-items-centers">
                  <b-col cols="auto">
                        <b-img :src="flag(team)" />                  
                        <span class="ml-2">{{team.dispname}}</span>
                  </b-col>
            </b-row>
      </div>
      <b-spinner variant="primary" v-else></b-spinner>
</b-dropdown>
</template>

<script>
export default {
      name: "TeamSelect",
      props: {"id": String, showclear: Boolean},
      data() {
            return {};
      },
      computed: {
            loading() {
                  return this.$store.getters["teams/teamsloading"];
            },
            teams() {
                  var all = this.$store.getters["teams/teams"];
                  var teams = [];
                  for (var id in all) {
                        teams.push(all[id]);
                  }
                  return teams.sort(function (a, b) {
                        if (a.dispname < b.dispname) //sort string ascending
                              return -1
                        if (a.dispname > b.dispname)
                              return 1
                        return 0 //default return value (no sorting)
                  });
            },
            selected() {
                  var t = this.$functions.getTeam(this.id);
                  return t;
            }
      },
      methods: {
            flag(team) {
                  return this.$images.teamFlag(team.flag);
            },
            SelectTeam(team)
            {
                  this.$emit("selected", team);
            }
      }
};
</script>
